import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import { useStyles } from "./CloudProviderCardStyles";

export interface CloudProviderCardProps {
  provider: "AWS" | "GCP" | "Azure";
  isSelected: boolean;
  onSelect: () => void;
}

export const CloudProviderCard = ({
  provider,
  isSelected,
  onSelect,
}: CloudProviderCardProps) => {
  const classes = useStyles({ isSelected });
  return (
    <Card className={classes.card} onClick={onSelect}>
      <CardMedia
        component="img"
        alt={`${provider} Logo`}
        image={`/svg/${provider.toLowerCase()}.svg`}
        title={`${provider} Logo`}
        className={classes.cardMedia}
      />
    </Card>
  );
};
