import {
  configApiRef,
  createPlugin,
  createApiFactory,
  discoveryApiRef,
  createComponentExtension,
  fetchApiRef,
} from "@backstage/core-plugin-api";

import { datadogApiRef, DatadogClient } from "./api";

export const datadogPlugin = createPlugin({
  id: "datadog",
  apis: [
    createApiFactory({
      api: datadogApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        configApi: configApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, configApi, fetchApi }) => {
        return new DatadogClient({
          discoveryApi,
          configApi,
          fetchApi,
        });
      },
    }),
  ],
});

export const AwsDashboard = datadogPlugin.provide(
  createComponentExtension({
    name: "AwsDashboard",
    component: {
      lazy: () =>
        import("./components/AwsDashboard").then((m) => m.AwsDashboard),
    },
  }),
);

export const GcpDashboard = datadogPlugin.provide(
  createComponentExtension({
    name: "GcpDashboard",
    component: {
      lazy: () =>
        import("./components/GcpDashboard").then((m) => m.GcpDashboard),
    },
  }),
);
export const AzureDashboard = datadogPlugin.provide(
  createComponentExtension({
    name: "AzureDashboard",
    component: {
      lazy: () =>
        import("./components/AzureDashboard").then((m) => m.AzureDashboard),
    },
  }),
);
