import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  list: {
    backgroundColor: theme.palette.type === "dark" ? "#444444" : "#000000",
  },
  hover: {
    color: "#FFFFFF",
    padding: theme.spacing(1, 2),
    margin: 0,
    display: "flex",
    alignItems: "center",
    "&:hover": {
      background:
        theme.palette.navigation.navItem?.hoverBackground || "#404040",
      cursor: "pointer",
    },
  },
  drawerToolsMenu: {
    fontSize: "1rem",
    padding: theme.spacing(0.3, 0),
    color: theme.palette.type === "dark" ? "#FFFFFF" : "#555555",
    backgroundColor: "transparent",
    boxShadow: "none",
    textDecoration: "none",
    width: 250,
    height: 40,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  customPaper: {
    backgroundColor: "#555555 !important",
  },
}));

export default useStyles;
