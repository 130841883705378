import { useState } from "react";
import { Content, Header, Page } from "@backstage/core-components";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  AwsDashboard,
  GcpDashboard,
  AzureDashboard,
} from "../components/datadog";
import { Typography } from "@material-ui/core";
import { CloudProviderCard } from "../components/shared/CloudProviderCard";

type CloudProvider = "AWS" | "GCP" | "Azure";

export function CostPage() {
  const [selectedCloud, setSelectedCloud] = useState<CloudProvider>("AWS");
  const renderDashboard = () => {
    switch (selectedCloud) {
      case "AWS":
        return <AwsDashboard />;
      case "GCP":
        return <GcpDashboard />;
      case "Azure":
        return <AzureDashboard />;
      default:
        return (
          <CircularProgress style={{ display: "block", margin: "0 auto" }} />
        );
    }
  };

  return (
    <Page themeId="tool">
      <Header title="Cloud Cost Dashboard" />
      <Content>
        <Typography variant="h3" style={{ marginBottom: "16px" }}>
          Cloud Provider
        </Typography>
        <Grid
          container
          justifyContent="flex-start"
          style={{ marginBottom: "20px" }}
        >
          {["AWS", "GCP", "Azure"].map((provider) => (
            <Grid item xs={4} sm={2} md={2} lg={1} key={provider}>
              <CloudProviderCard
                provider={provider as CloudProvider}
                isSelected={selectedCloud === provider}
                onSelect={() => setSelectedCloud(provider as CloudProvider)}
              />
            </Grid>
          ))}
        </Grid>

        {renderDashboard()}
      </Content>
    </Page>
  );
}
