import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appbar: {
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  button: {
    "&:hover": {
      background:
        theme.palette.navigation.navItem?.hoverBackground || "#404040",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
      padding: theme.spacing(1),
    },
  },
  search: {
    borderRadius: 0,
    "&:hover": {
      borderRadius: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
    },
  },
  menuDesktop: {
    display: "flex",
    gap: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hamburgerMenu: {
    display: "none",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  drawer: {
    width: 250,
  },
  drawerPaper: {
    backgroundColor: theme.palette.background.default,
  },
  toolsMenuContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

export default useStyles;
