import {
  ConfigApi,
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from "@backstage/core-plugin-api";

export const datadogApiRef = createApiRef<DatadogApi>({
  id: "plugin.datadog.service",
});

const DEFAULT_PROXY_PATH = "/datadog/api";
const DEFAULT_REST_API_VERSION = "v1";

type Options = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  fetchApi: FetchApi;
};

interface ServiceCostData {
  service: string;
  costByDate: Record<string, number>;
}

interface DatadogSeries {
  pointlist?: [number, string][];
  tag_set?: string[];
}

interface DatadogApiResponse {
  series?: DatadogSeries[];
}

export interface DatadogApi {
  fetchTotalCostData(
    days: number,
    account?: string,
    region?: string,
  ): Promise<number>;
  fetchCostByService(
    days: number,
    account?: string,
    region?: string,
  ): Promise<ServiceCostData[]>;
}
export class DatadogClient implements DatadogApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly proxyPath: string;
  private readonly apiVersion: string;
  private readonly fetchApi: FetchApi;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    const proxyPath = options.configApi.getOptionalString("datadog.proxyPath");
    this.proxyPath = proxyPath ?? DEFAULT_PROXY_PATH;

    const apiVersion =
      options.configApi.getOptionalNumber("datadog.apiVersion");
    this.apiVersion = apiVersion
      ? apiVersion.toString()
      : DEFAULT_REST_API_VERSION;

    this.fetchApi = options.fetchApi;
  }
  getCostMonthly(): Promise<number> {
    throw new Error("Method not implemented.");
  }
  getCostDaily(): Promise<number> {
    throw new Error("Method not implemented.");
  }

  private async getUrls() {
    const proxyUrl = await this.discoveryApi.getBaseUrl("proxy");
    return {
      apiUrl: `${proxyUrl}${this.proxyPath}/api/${this.apiVersion}/`,
      baseUrl: `${proxyUrl}${this.proxyPath}`,
    };
  }

  async fetchTotalCostData(
    days: number,
    accountId?: string,
    region?: string,
  ): Promise<number> {
    const now = new Date();
    const to = Math.floor(now.getTime() / 1000); // Current timestamp as the end time

    // Set the start date to 'days' days ago at 00:00:00 (midnight)
    const fromDate = new Date(now);
    fromDate.setDate(now.getDate() - days);
    fromDate.setHours(0, 0, 0, 0);
    const from = Math.floor(fromDate.getTime() / 1000);

    const accountFilter =
      accountId && accountId !== "all"
        ? `subaccountid:${accountId}`
        : "subaccountid:*";
    const regionFilter =
      region && region !== "all" ? `,aws_region:${region}` : ",aws_region:*";

    const query = encodeURIComponent(
      `sum:aws.cost.ondemand{${accountFilter}${regionFilter}}`,
    );
    const { apiUrl } = await this.getUrls();
    const url = `${apiUrl}query?from=${from}&to=${to}&query=${query}`;

    const response = await this.fetchApi.fetch(url, {
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch cost data: ${response.statusText}`);
    }

    const data: DatadogApiResponse = await response.json();
    if (!data.series || !Array.isArray(data.series)) {
      throw new Error(
        "Unexpected response structure: 'series' field is missing.",
      );
    }

    const totalCost = data.series.reduce((sum: number, series) => {
      const seriesTotal = (series.pointlist ?? [])
        .filter((point) => point[0] / 1000 >= from && point[0] / 1000 <= to)
        .reduce((acc, point) => acc + parseFloat(point[1] || "0"), 0);

      return sum + seriesTotal;
    }, 0);

    return totalCost;
  }

  async fetchCostByService(
    days: number,
    accountId?: string,
    region?: string,
  ): Promise<ServiceCostData[]> {
    const now = new Date();
    const to = Math.floor(now.getTime() / 1000); // Current timestamp as the end time

    // Set the start date to 'days' days ago at 00:00:00 (midnight)
    const fromDate = new Date(now);
    fromDate.setDate(now.getDate() - days);
    fromDate.setHours(0, 0, 0, 0);
    const from = Math.floor(fromDate.getTime() / 1000);

    const accountFilter =
      accountId && accountId !== "all"
        ? `subaccountid:${accountId}`
        : "subaccountid:*";
    const regionFilter =
      region && region !== "all" ? `,aws_region:${region}` : ",aws_region:*";

    const rollupPeriod = days > 90 ? "monthly" : "daily";

    const query = encodeURIComponent(
      `sum:aws.cost.ondemand{${accountFilter}${regionFilter}} by {aws_product}.rollup(sum, ${rollupPeriod})`,
    );

    const { apiUrl } = await this.getUrls();
    const url = `${apiUrl}query?from=${from}&to=${to}&query=${query}`;

    console.log(`Fetching data with URL: ${url}`); // Debug log

    const response = await this.fetchApi.fetch(url, {
      headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
      throw new Error(
        `Failed to fetch cost by service data: ${response.statusText}`,
      );
    }

    const data: DatadogApiResponse = await response.json();

    if (
      !data.series ||
      !Array.isArray(data.series) ||
      data.series.length === 0
    ) {
      console.error("Unexpected or empty 'series' structure in API response.");
      return [];
    }

    // Extract the dates and cost data
    const serviceCosts = data.series.map((series) => {
      const awsProductTag = series.tag_set?.find((tag) =>
        tag.startsWith("aws_product:"),
      );
      const service = awsProductTag
        ? awsProductTag.split(":")[1].charAt(0).toUpperCase() +
          awsProductTag.split(":")[1].slice(1).toLowerCase()
        : "Unknown";

      const costByDate: { [key: string]: number } = {}; // Mapping of date -> cost per service

      (series.pointlist ?? [])
        .filter((point) => point[0] / 1000 >= from && point[0] / 1000 <= to)
        .forEach((point) => {
          const timestamp = new Date(point[0]); // timestamp in milliseconds
          const date = timestamp.toISOString().split("T")[0];
          const cost = parseFloat(point[1] || "0");

          if (costByDate[date]) {
            costByDate[date] += cost; // Add up the cost for that specific date
          } else {
            costByDate[date] = cost;
          }
        });

      // Return service name along with the costs grouped by each date
      return {
        service,
        costByDate,
      };
    });

    console.log("Grouped Service Costs:", serviceCosts); // Debug log
    return serviceCosts;
  }
}
