import { Grid } from "@material-ui/core";
import { MendLibrariesCard } from "../../components/mend";

export const dependenciesContent = (
  <Grid container spacing={2}>
    <Grid item xs={12} md={6}>
      <MendLibrariesCard />
    </Grid>
  </Grid>
);
