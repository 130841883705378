import { useState } from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { Progress, ResponseErrorPanel } from "@backstage/core-components";
import { useApi } from "@backstage/core-plugin-api";
import {
  catalogApiRef,
  useStarredEntities,
  entityRouteParams,
  entityRouteRef,
  getEntityRelations,
} from "@backstage/plugin-catalog-react";
import {
  CompoundEntityRef,
  Entity,
  stringifyEntityRef,
} from "@backstage/catalog-model";
import { GridColDef } from "@mui/x-data-grid";
import { useAsync } from "react-use";
import { useRouteRef } from "@backstage/core-plugin-api";
import { CustomDataGrid } from "../../shared/CustomDataGrid/CustomDataGrid";
import GradeIcon from "@mui/icons-material/Grade";
import { CardTitle } from "../../shared/CardTitle";
import useStyles from "./FavouriteCardStyle";
import { RELATION_OWNED_BY } from "@backstage/catalog-model";
import TeamMemberDisplay from "../../shared/TeamMemberDisplay/TeamMemberDisplay";

export const buildStarredEntityRow = (
  entity: Entity,
  isFavorite: boolean,
  toggleStar: (entityRef: string) => void,
  catalogEntityRoute: (params: {
    name: string;
    kind: string;
    namespace: string;
  }) => string,
) => {
  const entityRef = stringifyEntityRef(entity);
  const type = entity.spec?.type || "N/A";
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

  return {
    id: entityRef,
    name: entity.metadata.name,
    type,
    ownedByRelations,
    link: catalogEntityRoute(entityRouteParams(entity)),
    isFavorite,
    toggleStar: () => toggleStar(entityRef),
  };
};

export const FavouriteCard = () => {
  const classes = useStyles();
  const catalogApi = useApi(catalogApiRef);
  const { starredEntities, toggleStarredEntity } = useStarredEntities();
  const [error, setError] = useState<Error | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const catalogEntityRoute = useRouteRef(entityRouteRef);

  const {
    loading,
    error: fetchError,
    value: entities,
  } = useAsync(async () => {
    try {
      if (!starredEntities.size) {
        return [];
      }
      const response = await catalogApi.getEntitiesByRefs({
        entityRefs: Array.from(starredEntities),
        fields: [
          "kind",
          "metadata.namespace",
          "metadata.name",
          "metadata.uid",
          "spec.type",
          "spec.owner",
          "relations",
        ],
      });
      return response.items.filter((e): e is Entity => !!e);
    } catch (e) {
      setError(e as Error);
      return [];
    }
  }, [catalogApi, starredEntities]);

  const columns: GridColDef[] = [
    {
      field: "isFavorite",
      headerName: "",
      width: 20,
      renderCell: ({ row }) => (
        <GradeIcon
          onClick={() => row.toggleStar()}
          className={classes.gradeIcon}
        />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: ({ row }) => (
        <a href={row.link} className={classes.link}>
          {row.name}
        </a>
      ),
    },
    { field: "type", headerName: "Type", flex: 1 },
    {
      field: "owner",
      headerName: "Owner",
      flex: 1,
      renderCell: ({ row }) => (
        <TeamMemberDisplay
          teamMembers={row.ownedByRelations.map(
            (relation: CompoundEntityRef) => ({
              name: relation.name,
              avatarUrl: relation.namespace || undefined,
              entityRef: relation,
            }),
          )}
        />
      ),
    },
  ];

  if (loading) return <Progress />;
  if (fetchError || error) {
    const displayError = fetchError || error;
    if (displayError) {
      return <ResponseErrorPanel error={displayError} />;
    }
  }
  if (!entities?.length) {
    return (
      <Card>
        <CardContent>
          <CardTitle
            text="Favorite Entities"
            icon={<GradeIcon />}
            className={classes.starIcon}
          />
          <Typography variant="body1">
            You have no favorite entities. Click the star beside an entity to
            add it to your favorites!
          </Typography>
        </CardContent>
      </Card>
    );
  }

  const rows = entities
    .sort((a, b) =>
      (a.metadata.title ?? a.metadata.name).localeCompare(
        b.metadata.title ?? b.metadata.name,
      ),
    )
    .map((entity) =>
      buildStarredEntityRow(
        entity,
        starredEntities.has(entity.metadata.uid || ""),
        toggleStarredEntity,
        catalogEntityRoute,
      ),
    );

  return (
    <Card>
      <CardContent>
        <CardTitle
          text="Favorite Entities"
          icon={<GradeIcon />}
          className={classes.starIcon}
        />
        <CustomDataGrid
          rows={rows}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </CardContent>
    </Card>
  );
};
