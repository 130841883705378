import { Route } from "react-router-dom";
import { ApiExplorerPage } from "@backstage/plugin-api-docs";
import { CatalogEntityPage, CatalogIndexPage } from "./components/catalog";
import { CatalogImportPage } from "@backstage/plugin-catalog-import";
import { ScaffolderPage } from "@backstage/plugin-scaffolder";
import { SearchPage } from "@backstage/plugin-search";
import { TechRadarPage } from "@backstage-community/plugin-tech-radar";
import {
  DefaultTechDocsHome,
  TechDocsIndexPage,
  TechDocsReaderPage,
} from "@backstage/plugin-techdocs";
import { TechDocsAddons } from "@backstage/plugin-techdocs-react";
import { ReportIssue } from "@backstage/plugin-techdocs-module-addons-contrib";
import { UserSettingsPage } from "@backstage/plugin-user-settings";
import { entityPage } from "./pages/EntityPage";
import { searchPage } from "./components/search/SearchPage";
import { FlatRoutes } from "@backstage/core-app-api";
import { CatalogGraphPage } from "@backstage/plugin-catalog-graph";
import { RequirePermission } from "@backstage/plugin-permission-react";
import { catalogEntityCreatePermission } from "@backstage/plugin-catalog-common/alpha";
import { settingsPage } from "./components/settings/SettingsPage";
import { HomepageCompositionRoot } from "@backstage/plugin-home";
import { HomePage } from "./pages/HomePage";
import { costPermission } from "./components/datadog/permission";
import { EntityJiraOverviewCard } from "./components/jira";
import { SelfServicePage } from "./components/self-service/SelfService";
import { OngoingTask } from "./pages/CustomTaskPage";
import { ScaffolderFieldExtensions } from "@backstage/plugin-scaffolder-react";
import { ValidateGithubNameFieldExtension } from "./components/scaffolder";
import { CostPage } from "./pages/CostPage";
//import { AwsDashboard } from "./components/datadog";

const CustomPageTask = () => {
  return <OngoingTask />;
};

export const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route
      path="/catalog"
      element={
        <CatalogIndexPage
          pagination
          initiallySelectedFilter="all"
          initialKind="component"
        />
      }
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <DefaultTechDocsHome />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <ScaffolderPage components={{ TaskPageComponent: CustomPageTask }} />
      }
    >
      <ScaffolderFieldExtensions>
        <ValidateGithubNameFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />}>
      {settingsPage}
    </Route>
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route
      path="/cost"
      element={
        <RequirePermission permission={costPermission}>
          <CostPage />
        </RequirePermission>
      }
    />
    <Route path="/jira-dashboard" element={<EntityJiraOverviewCard />} />
    <Route path="/self-service" element={<SelfServicePage />} />
  </FlatRoutes>
);
