import {
  Card,
  CardContent,
  Avatar,
  Box,
  Tooltip,
  Grid,
} from "@material-ui/core";
import LinkIcon from "@mui/icons-material/Link";
import useStyles from "./UsefullLinksStyle";
import { CardTitle } from "../../shared/CardTitle";
import { linksData } from "./linksData";

export const UsefulLinks = () => {
  const classes = useStyles();

  const handleClick = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <CardTitle
          text="Useful Links"
          icon={<LinkIcon />}
          className={classes.linkIcon}
        />
        <Box className={classes.boxContainer}>
          <Grid container spacing={3}>
            {linksData.map((link) => (
              <Grid
                item
                key={link.name}
                xs={6}
                sm={4}
                md={2}
                lg={1}
                className={classes.gridItem}
                onClick={() => handleClick(link.url)}
              >
                <Tooltip title={link.name}>
                  <Avatar
                    className={classes.avatar}
                    src={link.logo}
                    alt={link.name}
                  />
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};
