import { useEffect, useState } from "react";
import { Card, CardContent, LinearProgress } from "@material-ui/core";
import UploadIcon from "@mui/icons-material/Upload";
import useStyles from "./TeamInsightsCardStyles";
import { teamsData } from "./teamsData";
import { ScoreBadge } from "../../shared/ScoreBadge";
import { CustomDataGrid } from "../../shared/CustomDataGrid/CustomDataGrid";
import TeamMemberDisplay from "../../shared/TeamMemberDisplay/TeamMemberDisplay";
import { CatalogApi, catalogApiRef } from "@backstage/plugin-catalog-react";
import { useApi } from "@backstage/core-plugin-api";
import { CardTitle } from "../../shared/CardTitle";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

interface TeamData {
  id: number;
  initials: string;
  teamName: string;
  name: string;
  namespace?: string;
  productionReadiness: number;
  monitoringMaturity: number;
  doraMetrics: number;
  avatarUrl?: string;
}

interface TeamRow {
  name: string;
  avatarUrl: string;
  entityRef: {
    kind: string;
    name: string;
    namespace: string;
  };
  score: number;
  productionReadiness: number;
  monitoringMaturity: number;
  doraMetrics: number;
}

export const TeamInsightsCard = () => {
  const classes = useStyles();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  const calculateScore = (team: TeamData): number => {
    const meanScore =
      (team.productionReadiness + team.monitoringMaturity + team.doraMetrics) /
      3;
    return Math.ceil(meanScore);
  };

  const catalogApi = useApi(catalogApiRef);

  const fetchEntityRefs = async (
    teamsData: TeamData[],
    catalogApi: CatalogApi,
  ): Promise<TeamRow[]> => {
    const teamPromises = teamsData.map(async (team) => {
      try {
        const entity = await catalogApi.getEntityByRef({
          kind: "Group",
          name: team.name,
          namespace: team.namespace || "default",
        });

        return {
          ...team,
          avatarUrl: team.avatarUrl || "/png/vf-icon.png",
          entityRef: entity
            ? {
                kind: entity.kind,
                name: entity.metadata.name,
                namespace: entity.metadata.namespace || "default",
              }
            : { kind: "Group", name: team.name, namespace: "default" },
          score: calculateScore(team),
        };
      } catch (error) {
        console.error(`Error getting entityRef for ${team.name}:`, error);
        return {
          ...team,
          avatarUrl: "/png/vf-icon.png",
          entityRef: { kind: "Group", name: team.name, namespace: "default" },
          score: calculateScore(team),
        };
      }
    });

    return Promise.all(teamPromises);
  };

  const useTeamRows = (teamsData: TeamData[]): TeamRow[] => {
    const [rows, setRows] = useState<TeamRow[]>([]);

    useEffect(() => {
      const loadRows = async () => {
        const enrichedRows = await fetchEntityRefs(teamsData, catalogApi);
        setRows(enrichedRows);
      };

      loadRows();
    }, [teamsData]);

    return rows;
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "TEAM",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <TeamMemberDisplay
          teamMembers={[
            {
              name: params.row.teamName,
              avatarUrl: params.row.avatarUrl,
              entityRef: params.row.entityRef,
            },
          ]}
        />
      ),
    },
    {
      field: "productionReadiness",
      headerName: "PRODUCTION READINESS",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <LinearProgress
          variant="determinate"
          value={params.value || 0}
          className={classes.progressBar}
          classes={{ bar: classes.productionReadinessColor }}
        />
      ),
    },
    {
      field: "monitoringMaturity",
      headerName: "MONITORING MATURITY",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <LinearProgress
          variant="determinate"
          value={params.value || 0}
          className={classes.progressBar}
          classes={{ bar: classes.monitoringMaturityColor }}
        />
      ),
    },
    {
      field: "doraMetrics",
      headerName: "DORA METRICS",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <LinearProgress
          variant="determinate"
          value={params.value || 0}
          className={classes.progressBar}
          classes={{ bar: classes.doraMetricsColor }}
        />
      ),
    },
    {
      field: "score",
      headerName: "SCORE",
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <ScoreBadge score={params.value || 0} size="medium" />
      ),
    },
  ];

  const rows = useTeamRows(teamsData);

  return (
    <Card>
      <CardContent>
        <CardTitle
          icon={<UploadIcon />}
          text="Team Insights"
          className={classes.rankIcon}
        />
        <CustomDataGrid
          rows={rows}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </CardContent>
    </Card>
  );
};
