import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    gap: "8px",
  },
  value: {
    textAlign: "left",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  avatar: { height: "24px", width: "24px", marginRight: "8px" },
}));

export default useStyles;
