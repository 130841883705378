import { EntityRefLink } from "@backstage/plugin-catalog-react";
import { Avatar, Box, Typography } from "@material-ui/core";
import useStyles from "./TeamMemberDisplayStyle";
import { CompoundEntityRef } from "@backstage/catalog-model";

interface TeamMember {
  name: string;
  avatarUrl?: string;
  entityRef: CompoundEntityRef;
}

interface TeamMemberDisplayProps {
  teamMembers: TeamMember[];
}

const TeamMemberDisplay = ({ teamMembers }: TeamMemberDisplayProps) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      className={classes.boxContainer}
    >
      {teamMembers.map((member) => (
        <EntityRefLink entityRef={member.entityRef} key={member.name}>
          <Box display="flex" alignItems="center" marginRight={2}>
            <Avatar src={member.avatarUrl} className={classes.avatar} />
            <Typography variant="body2" className={classes.value}>
              {member.name}
            </Typography>
          </Box>
        </EntityRefLink>
      ))}
    </Box>
  );
};

export default TeamMemberDisplay;
