import {
  ConfigApi,
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from "@backstage/core-plugin-api";
import {
  IssueCountResult,
  IssueCountSearchParams,
  IssuesCounter,
  IssueType,
  Project,
  Status,
  Ticket,
} from "../types";

export const jiraApiRef = createApiRef<JiraAPI>({
  id: "plugin.jira.service",
});

const DEFAULT_PROXY_PATH = "/jira/api";
const DEFAULT_REST_API_VERSION = "latest";
const DONE_STATUS_CATEGORY = "Done";

type Options = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  fetchApi: FetchApi;
};

interface JiraTicketFields {
  projectKey: string;
  summary: string;
  description: string;
  issueType?: string;
  [key: string]: any; // Allows additional fields dynamically
}

export class JiraAPI {
  private readonly discoveryApi: DiscoveryApi;
  private readonly proxyPath: string;
  private readonly apiVersion: string;
  private readonly fetchApi: FetchApi;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;

    const proxyPath = options.configApi.getOptionalString("jira.proxyPath");
    this.proxyPath = proxyPath ?? DEFAULT_PROXY_PATH;

    const apiVersion = options.configApi.getOptionalNumber("jira.apiVersion");
    this.apiVersion = apiVersion
      ? apiVersion.toString()
      : DEFAULT_REST_API_VERSION;

    this.fetchApi = options.fetchApi;
  }

  private generateProjectUrl = (url: string) =>
    new URL(url).origin +
    new URL(url).pathname.replace(/\/rest\/api\/.*$/g, "");

  private async getUrls() {
    const proxyUrl = await this.discoveryApi.getBaseUrl("proxy");
    return {
      apiUrl: `${proxyUrl}${this.proxyPath}/rest/api/${this.apiVersion}/`,
      baseUrl: `${proxyUrl}${this.proxyPath}`,
    };
  }

  private convertToString = (arrayElement: Array<string>): string =>
    arrayElement
      .filter(Boolean)
      .map((i) => `'${i}'`)
      .join(",");

  private async pagedIssueCountRequest(
    apiUrl: string,
    jql: string,
    startAt: number,
  ): Promise<IssueCountResult> {
    const data = {
      jql,
      maxResults: -1,
      fields: [
        "key",
        "issuetype",
        "summary",
        "status",
        "assignee",
        "priority",
        "created",
        "updated",
      ],
      startAt,
    };

    const request = await this.fetchApi.fetch(`${apiUrl}search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!request.ok) {
      throw new Error(
        `failed to fetch data, status ${request.status}: ${request.statusText}`,
      );
    }
    const response: IssueCountSearchParams = await request.json();
    const lastElement = response.startAt + response.maxResults;

    return {
      issues: response.issues,
      next: response.total > lastElement ? lastElement : undefined,
    };
  }

  private async getIssueCountPaged({
    apiUrl,
    projectKey,
    component,
    label,
    statusesNames,
  }: {
    apiUrl: string;
    projectKey: string;
    component: string;
    label: string;
    statusesNames: Array<string>;
  }) {
    const statusesString = this.convertToString(statusesNames);

    const jql = `project = "${projectKey}"
        ${statusesString ? `AND status in (${statusesString})` : ""}
        ${component ? `AND component = "${component}"` : ""}
        ${label ? `AND labels in ("${label}")` : ""}
        AND statuscategory not in ("Done") 
      `;

    let startAt: number | undefined = 0;
    const issues: Ticket[] = [];

    while (startAt !== undefined) {
      const res: IssueCountResult = await this.pagedIssueCountRequest(
        apiUrl,
        jql,
        startAt,
      );
      startAt = res.next;
      issues.push(...res.issues);
    }

    return issues;
  }

  private async getIssuesCountByType({
    apiUrl,
    projectKey,
    component,
    statusesNames,
    issueType,
    issueIcon,
    label,
  }: {
    apiUrl: string;
    projectKey: string;
    component: string;
    statusesNames: Array<string>;
    issueType: string;
    issueIcon: string;
    label: string;
  }) {
    const statusesString = this.convertToString(statusesNames);

    const jql = `project = "${projectKey}"
        AND issuetype = "${issueType}"
        ${statusesString ? `AND status in (${statusesString})` : ""}
        ${component ? `AND component = "${component}"` : ""}
        AND statuscategory not in ("Done") ${
          label ? `AND labels in ("${label}")` : ""
        }
      `;
    const data = {
      jql,
      maxResults: 0,
    };
    const request = await this.fetchApi.fetch(`${apiUrl}search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!request.ok) {
      throw new Error(
        `failed to fetch data, status ${request.status}: ${request.statusText}`,
      );
    }
    const response = await request.json();
    return {
      total: response.total,
      name: issueType,
      iconUrl: issueIcon,
    } as IssuesCounter;
  }

  async getProjectDetails(
    projectKey: string,
    component: string,
    label: string,
    statusesNames: Array<string>,
  ) {
    const { apiUrl } = await this.getUrls();

    const request = await this.fetchApi.fetch(
      `${apiUrl}project/${projectKey}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (!request.ok) {
      throw new Error(
        `failed to fetch data, status ${request.status}: ${request.statusText}`,
      );
    }
    const project = (await request.json()) as Project;

    // If component not defined, execute the same code. Otherwise use paged request
    // to fetch also the issue-keys of all the tasks for that component.
    let issuesCounter: IssuesCounter[] = [];
    let ticketIds: string[] = [];
    let tickets: Ticket[] = [];
    const foundIssues = await this.getIssueCountPaged({
      apiUrl,
      projectKey,
      component,
      label,
      statusesNames,
    });
    if (!component && !label) {
      // Generate counters for each issue type
      const issuesTypes = project.issueTypes.map((status: IssueType) => ({
        name: status.name,
        iconUrl: status.iconUrl,
      }));

      const filteredIssues = issuesTypes.filter((el) => el.name !== "Sub-task");

      issuesCounter = await Promise.all(
        filteredIssues.map((issue) => {
          const issueType = issue.name;
          const issueIcon = issue.iconUrl;
          return this.getIssuesCountByType({
            apiUrl,
            projectKey,
            component,
            statusesNames,
            issueType,
            issueIcon,
            label,
          });
        }),
      );
    } else {
      // Get all issues, count them using reduce and generate a ticketIds array,
      // used to filter in the activity stream
      const issuesTypes = project.issueTypes.map(
        (status: IssueType): IssuesCounter => ({
          name: status.name,
          iconUrl: status.iconUrl,
          total: 0,
        }),
      );
      issuesCounter = foundIssues
        .reduce((prev, curr) => {
          const name = curr.fields?.issuetype.name;
          const idx = issuesTypes.findIndex((i) => i.name === name);
          if (idx !== -1) {
            issuesTypes[idx].total++;
          }
          return prev;
        }, issuesTypes)
        .filter((el) => el.name !== "Sub-task");

      ticketIds = foundIssues.map((i) => i.key);
    }
    tickets = foundIssues.map((index) => {
      return {
        id: index.id,
        key: index.key,
        summary: index?.fields?.summary,
        assignee: {
          displayName: index?.fields?.assignee?.displayName,
          avatarUrl: index?.fields?.assignee?.avatarUrls["48x48"],
        },
        status: index?.fields?.status?.name,
        priority: index?.fields?.priority,
        created: index?.fields?.created,
        updated: index?.fields?.updated,
      };
    });
    return {
      project: {
        name: project.name,
        iconUrl: project.avatarUrls["48x48"],
        type: project.projectTypeKey,
        url: this.generateProjectUrl(project.self),
      },
      issues:
        issuesCounter && issuesCounter.length
          ? issuesCounter.map((status) => ({
              ...status,
            }))
          : [],
      ticketIds: ticketIds,
      tickets,
    };
  }

  async getStatuses(projectKey: string) {
    const { apiUrl } = await this.getUrls();

    const request = await this.fetchApi.fetch(
      `${apiUrl}project/${projectKey}/statuses`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (!request.ok) {
      throw new Error(
        `failed to fetch data, status ${request.status}: ${request.statusText}`,
      );
    }
    const statuses = (await request.json()) as Array<Status>;

    return [
      ...new Set(
        statuses
          .flatMap((status) => status.statuses)
          .filter(
            (status) => status.statusCategory?.name !== DONE_STATUS_CATEGORY,
          )
          .map((it) => it.name)
          .reduce((acc, val) => {
            acc.push(val);
            return acc;
          }, [] as string[]),
      ),
    ];
  }

  async getReporter(userEmail: string) {
    try {
      const { apiUrl } = await this.getUrls();
      const request = await this.fetchApi.fetch(
        `${apiUrl}user?username=${userEmail}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (!request.ok) {
        console.warn(
          `User with email ${userEmail} not found. Setting reporter to an empty string.`,
        );
        return "";
      }

      const reporter = await request.json();
      return reporter;
    } catch (err) {
      console.error(
        "Failed to fetch the user email, defaulting reporter to empty string:",
        err,
      );
      return "";
    }
  }

  async addAttachment({
    issueKey,
    files,
  }: {
    issueKey: string;
    files: File[] | null;
  }): Promise<void> {
    if (!files || files.length === 0) {
      return;
    }

    const { apiUrl } = await this.getUrls();
    const form = new FormData();

    for (const file of files) {
      const originalFileName = file.name;
      const modifiedFileName = originalFileName.replace(/\s+/g, "_");
      form.append("file", file, modifiedFileName);
    }

    const request = await this.fetchApi.fetch(
      `${apiUrl}issue/${issueKey}/attachments`,
      {
        method: "POST",
        body: form,
      },
    );

    if (!request.ok) {
      const errorText = await request.text();
      throw new Error(
        `Failed to add attachment to Jira issue, status ${request.status}: ${request.statusText}, response: ${errorText}`,
      );
    }
  }

  async createJiraTicket({
    projectKey,
    summary,
    description,
    issueType, // Default issue type can be customized
    reporter,
    ...extrafields
  }: JiraTicketFields): Promise<{ key: string }> {
    const { apiUrl } = await this.getUrls();

    const data = {
      fields: {
        project: {
          key: projectKey,
        },
        summary: summary,
        description: description,
        issuetype: {
          name: issueType,
        },
        reporter: reporter,
        ...extrafields,
      },
    };

    const request = await this.fetchApi.fetch(`${apiUrl}issue`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!request.ok) {
      throw new Error(
        `Failed to create Jira ticket, status ${request.status}: ${request.statusText}`,
      );
    }

    const response = await request.json(); // Get response JSON
    return { key: response.key }; // Return the ticket ID
  }

  async getIssuesAssignedToUser(userEmail: string) {
    try {
      const { apiUrl } = await this.getUrls();
      const jql = `assignee="${userEmail}" AND status in ("In Progress", "Draft", "On Hold", "Blocked")`;
      const data = {
        jql: jql,
        fields: [
          "key",
          "issuetype",
          "summary",
          "status",
          "assignee",
          "priority",
          "created",
          "updated",
        ],
        maxResults: -1,
      };

      const response = await this.fetchApi.fetch(`${apiUrl}search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch Jira issues, status ${response.status}`,
        );
      }

      return response.json();
    } catch (error) {
      console.error("Error fetching data from Jira:", error);
      throw error;
    }
  }
}
