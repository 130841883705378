import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from "@backstage/core-plugin-api";

export const mendApiRef = createApiRef<MendApi>({
  id: "plugin.mend.service",
});

export interface MendApi {
  getVulnerabilities(
    projectName: string,
  ): Promise<{ [severity: string]: number }>;
  getLicenses(projectName: string): Promise<{ [licenseName: string]: number }>;
  getLibraries(projectName: string): Promise<{ [libraryName: string]: number }>;
}

export class MendClient implements MendApi {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  async getVulnerabilities(
    projectName: string,
  ): Promise<{ [severity: string]: number }> {
    const url = `${await this.discoveryApi.getBaseUrl("mend")}/vulnerabilities?projectName=${projectName}`;
    const response = await this.fetchApi.fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch vulnerabilities");
    }
    return response.json();
  }

  async getLicenses(
    projectName: string,
  ): Promise<{ [licenseName: string]: number }> {
    const url = `${await this.discoveryApi.getBaseUrl("mend")}/licenses?projectName=${projectName}`;
    const response = await this.fetchApi.fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch licenses");
    }
    return response.json();
  }

  async getLibraries(
    projectName: string,
  ): Promise<{ [libraryName: string]: number }> {
    const url = `${await this.discoveryApi.getBaseUrl("mend")}/libraries?projectName=${projectName}`;
    const response = await this.fetchApi.fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch libraries");
    }
    return response.json();
  }
}
