import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    paddingBottom: theme.spacing(0),
  },
  boxContainer: {
    marginTop: theme.spacing(-1),
  },
  linkIcon: {
    color: "#000",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover $avatar": {
      transform: "scale(1.1)",
      transition: "transform 0.3s ease",
    },
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "50%",
  },
}));

export default useStyles;
