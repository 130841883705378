export const linksData = [
  {
    name: "GitHub",
    logo: "/svg/github.svg",
    url: "https://github.com/enterprises/vodafone-group",
  },
  {
    name: "GitLab",
    logo: "/svg/gitlab.svg",
    url: "https://gitlab-ucc.tools.aws.vodafone.com/",
  },
  {
    name: "SonarQube",
    logo: "/svg/sonarqube.svg",
    url: "https://sonarqube.vodafone.com/",
  },
  {
    name: "Datadog",
    logo: "/svg/datadog.svg",
    url: "https://app.datadoghq.eu/account/login",
  },
  {
    name: "Mend",
    logo: "/png/mend.png",
    url: "https://saas-eu.whitesourcesoftware.com/Wss/WSS.html",
  },
  {
    name: "Confluence",
    logo: "/svg/confluence.svg",
    url: "https://confluence.tools.aws.vodafone.com/",
  },
  {
    name: "Jira",
    logo: "/svg/jira.svg",
    url: "https://jira.tools.aws.vodafone.com/",
  },
  {
    name: "Mongo",
    logo: "/svg/mongo.svg",
    url: "https://account.mongodb.com/",
  },
  {
    name: "JFrog",
    logo: "/svg/jfrog.svg",
    url: "https://artifactory.vodafone.com/",
  },
  {
    name: "AWS",
    logo: "/svg/aws.svg",
    url: "https://myapps.microsoft.com/signin/78f5464b-e016-4fdb-9478-ba285b87fb8e?tenantId=68283f3b-8487-4c86-adb3-a5228f18b893",
  },
  {
    name: "Jenkins",
    logo: "/svg/jenkins.svg",
    url: "https://build.tools.aws.vodafone.com/",
  },
  {
    name: "PCS",
    logo: "svg/vf-icon.svg",
    url: "https://portal.pcs.vodafone.com/",
  },
  { name: "Azure", logo: "/svg/azure.svg", url: "https://portal.azure.com/" },
  {
    name: "SourceWeb",
    logo: "/svg/sourceweb.svg",
    url: "https://github.vodafone.com/pages/Vodafone-Digital-Universe/source-web/index.html",
  },
];
