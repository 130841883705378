export const teamsData = [
  {
    id: 1,
    initials: "SA",
    teamName: "Solutions & Apps",
    name: "solutions-and-apps",
    productionReadiness: 70,
    monitoringMaturity: 50,
    doraMetrics: 10,
  },
  {
    id: 2,
    initials: "SH",
    teamName: "SolutionHub",
    name: "ppe-solutions-hub",
    productionReadiness: 50,
    monitoringMaturity: 80,
    doraMetrics: 50,
  },
  {
    id: 3,
    initials: "ESM",
    teamName: "ESM",
    name: "ESM",
    productionReadiness: 50,
    monitoringMaturity: 60,
    doraMetrics: 40,
  },
  {
    id: 4,
    initials: "UNI",
    teamName: "Unicaja",
    name: "unicaja-art-finder",
    productionReadiness: 95,
    monitoringMaturity: 70,
    doraMetrics: 80,
  },
];
