import {
  configApiRef,
  createPlugin,
  createApiFactory,
  discoveryApiRef,
  createComponentExtension,
  fetchApiRef,
} from "@backstage/core-plugin-api";

import { jiraApiRef, JiraAPI } from "./api";

export const jiraPlugin = createPlugin({
  id: "jira",
  apis: [
    createApiFactory({
      api: jiraApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        configApi: configApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, configApi, fetchApi }) => {
        return new JiraAPI({
          discoveryApi,
          configApi,
          fetchApi,
        });
      },
    }),
  ],
});

export const EntityJiraOverviewCard = jiraPlugin.provide(
  createComponentExtension({
    name: "EntityJiraOverviewCard",
    component: {
      lazy: () => import("./components/JiraCard").then((m) => m.JiraCard),
    },
  }),
);

export const PersonalJiraOverviewCard = jiraPlugin.provide(
  createComponentExtension({
    name: "EntityJiraOverviewCard",
    component: {
      lazy: () =>
        import("./components/AssignedTicketsCard").then(
          (m) => m.AssignedTicketsCard,
        ),
    },
  }),
);
