import {
  createPlugin,
  discoveryApiRef,
  createApiFactory,
  createComponentExtension,
  fetchApiRef,
} from "@backstage/core-plugin-api";
import { mendApiRef, MendClient } from "./api";

export const mendPlugin = createPlugin({
  id: "mend",
  apis: [
    createApiFactory({
      api: mendApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new MendClient({ discoveryApi, fetchApi }),
    }),
  ],
});

export const MendSecurityCard = mendPlugin.provide(
  createComponentExtension({
    name: "EntityJiraOverviewCard",
    component: {
      lazy: () =>
        import("./components/MendSecurityCard").then((m) => m.MendSecurityCard),
    },
  }),
);

export const MendLibrariesCard = mendPlugin.provide(
  createComponentExtension({
    name: "EntityJiraOverviewCard",
    component: {
      lazy: () =>
        import("./components/MendLibrariesCard").then(
          (m) => m.MendLibrariesCard,
        ),
    },
  }),
);
