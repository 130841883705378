import { Box, Avatar, Typography } from "@mui/material";
import { ReactNode } from "react";

interface CardTitleProps {
  text: string;
  className?: string;
  icon?: ReactNode;
  imageSrc?: string;
}

export const CardTitle = ({
  text,
  className,
  icon,
  imageSrc,
}: CardTitleProps) => {
  return (
    <Box display="flex" alignItems="center" mb={2}>
      {icon ? (
        <Box className={className}>{icon}</Box>
      ) : (
        <Avatar src={imageSrc} className={className} />
      )}
      <Typography variant="h5" gutterBottom>
        {text}
      </Typography>
    </Box>
  );
};
