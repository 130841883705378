import { ElementType, ReactNode, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link, SidebarGroup, SidebarItem } from "@backstage/core-components";
import { Box, styled } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CreateComponentIcon from "@material-ui/icons/AddCircleOutline";
import LogoFullWhite from "../branding/LogoFullWhite";
import ToolsMenu from "./ToolsMenu";
import { SidebarSearchModal } from "@backstage/plugin-search";
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from "@backstage/plugin-user-settings";
import { SettingsThemeToggle } from "./ThemeButton";
import useStyles from "./TopBarStyles";

const TopBarLogo = () => {
  const classes = useStyles();

  return (
    <div className={classes.logo}>
      <Link to="/" underline="none" aria-label="Home">
        <LogoFullWhite />
      </Link>
    </div>
  );
};

export const TopBarSpace = styled("div")({
  flex: 1,
});

type ItemProps = {
  icon?: ElementType;
  text?: string;
  to: string;
  hasSubmenu?: boolean;
  onClick?: (ev: React.MouseEvent) => void;
  children?: ReactNode;
};

export const NavItem = (Item: ItemProps) => {
  const classes = useStyles();

  return (
    <Link to={Item.to} underline="none" className={classes.appbar}>
      <Box
        display="flex"
        alignItems="center"
        sx={{ py: 2, px: 4 }}
        className={classes.button}
      >
        {Item.text}
      </Box>
    </Link>
  );
};

function ResponsiveAppBar() {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.MouseEvent | React.KeyboardEvent) => {
      if (
        event.type === "keydown" &&
        "key" in event &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const menuItems = [
    { text: "HOME", to: "/" },
    { text: "CATALOG", to: "/catalog" },
    { text: "SELF SERVICE", to: "/self-service" },
    { text: "DOCS", to: "/docs" },
  ];

  return (
    <>
      <AppBar position="fixed">
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            <TopBarLogo />
            <div className={classes.menuDesktop}>
              <SidebarGroup label="Menu" icon={<MenuIcon />}>
                {menuItems.map((item) => (
                  <NavItem key={item.text} to={item.to} text={item.text} />
                ))}
                <div className={classes.toolsMenuContainer}>
                  <ToolsMenu />
                </div>
              </SidebarGroup>
            </div>
            <TopBarSpace />
            <IconButton
              edge="start"
              className={classes.hamburgerMenu}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
              classes={{ paper: classes.drawerPaper }}
            >
              <List className={classes.drawer}>
                <ListItem button component={Link} to="/">
                  <ListItemText primary="HOME" />
                </ListItem>
                <ListItem button component={Link} to="/catalog">
                  <ListItemText primary="CATALOG" />
                </ListItem>
                <ListItem button component={Link} to="/self-service">
                  <ListItemText primary="SELF SERVICE" />
                </ListItem>
                <ListItem button component={Link} to="/docs">
                  <ListItemText primary="DOCS" />
                </ListItem>
                <ListItem button>
                  <ToolsMenu isHamburgerMenu={true} />
                </ListItem>
              </List>
            </Drawer>
            <SettingsThemeToggle />
            <SidebarGroup
              label="Search"
              icon={<SearchIcon className={classes.search} />}
            >
              <SidebarSearchModal />
            </SidebarGroup>
            <SidebarGroup
              label="Settings"
              icon={<UserSettingsSignInAvatar />}
              to="/settings"
            >
              <SidebarItem
                icon={CreateComponentIcon}
                to="create"
                text="Create..."
              />
              <SidebarSettings />
            </SidebarGroup>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </>
  );
}

export default ResponsiveAppBar;
