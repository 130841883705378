import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    starIcon: {
      color: "#f3ba37",
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    gradeIcon: {
      cursor: "pointer",
      color: "#f3ba37",
      fontSize: 24,
      transition: "color 0.3s ease, transform 0.3s ease",
      "&:hover": {
        color: "#9e9e9e",
        transform: "scale(1.1)",
      },
    },
    link: {
      textDecoration: "none",
      color: theme.palette.primary.main,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  };
});

export default useStyles;
